@import "../../../assets/styles/styles";

// TABLEAUX
.table {
  overflow: auto;
  //width: 95%;
  max-height: 70vh; // a revalider pour la hauteur du tableau et scroll
  margin: auto;
  background-color: $color-white;
}

.table__header {
  display: flex;
}

.table__line {
  display: flex;
  width: fit-content;
}

.table__group {
  display: flex;
  position: sticky;
  top: 0;
  min-height: 53px;
  height: 100%;

  &:hover {
    background-color: #f0f0f0;
    color: #000;
    transition: background-color 0.3s, color 0.3s;
  }
}

.table__sticky {
  position: sticky;
  top: 0;
  left: 0;
  border-right: 2px solid $color-black;
}

.table__header {
  position: sticky;
  top: 0;
  z-index: 12;
  width: fit-content;

  .table__sticky {
    z-index: 11;
  }
}

.table__title,
.table__item {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 48px;
  padding: 0 20px;
  line-height: 16px;
  vertical-align: middle;
  font-size: 14px;
  text-align: center;
  border-bottom: 1px solid $color-grey-light;
  word-break: break-word;

  &.status {
    width: 130px;
  }

  &.order-status {
    width: 200px;
  }

  &.validation {
    padding: 0 15px;
    width: 130px;
  }

  &.client-doc {
    width: 120px;
  }

  &.invoice {
    width: 125px;
  }

  &.quote-number {
    width: 160px;
  }

  &.order-number {
    width: 160px;
  }

  &.year {
    width: 130px;
  }

  &.client-name {
    width: 190px;
  }

  &.brand {
    width: 175px;
  }

  &.model {
    width: 240px;
  }

  &.price {
    width: 160px;
  }

  &.acknowledgment {
    width: 160px;
  }

  &.creation-date {
    width: 160px;
  }

  &.validity-date {
    width: 160px;
  }

  &.production-date {
    width: 180px;
  }

  &.invoice-number {
    width: 170px;
  }

  &.invoice-date {
    width: 160px;
  }

  &.info-client {
    width: 205px;
  }

  &.info-dealer {
    width: 200px;
  }

  &.client-phone {
    width: 160px;
  }

  &.dealer-name {
    width: 300px;
  }

  &.serie-number {
    width: 200px;
  }

  &.stock {
    width: 200px;
  }

  &.collection-date {
    width: 180px;
  }

  &.final-client {
    width: 150px;
  }

  &.stock-exchange {
    width: 170px;

    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  &.type {
    width: 200px;
  }

  &.dealer-location {
    width: 330px;
  }

  &.carrier {
    width: 210px;
  }

  &.bano {
    width: 125px;
  }

  &.billing-date {
    width: 140px;
  }

  &.date {
    width: 160px;
  }

  &.accountStatement-piece,
  &.accountStatement-label,
  &.accountStatement-date,
  &.accountStatement-debit,
  &.accountStatement-credit,
  &.accountStatement-total,
  &.accountStatement-balance {
    width: 180px;
  }

  // CSS COC Table
  &.to-request {
    width: 150px;
  }

  &.request-status {
    width: 170px;
  }

  &.request-date {
    width: 200px;
  }

  &.request-number {
    width: 220px;
  }

  &.invoice-number {
    width: 220px;
  }

  &.customer {
    width: 200px;
  }

  &.brand-coc {
    width: 200px;
  }

  &.model-coc {
    width: 200px;
  }

  &.vintage {
    width: 200px;
  }

  &.contact {
    width: 130px;
  }

  &.type {
    width: 160px;
  }

  &.zip {
    width: 150px;
  }

  &.commentary {
    width: 500px;
    justify-content: left;
  }

  &.see-more {
    width: 130px;

    .see-more-btn {
      padding: 8px 12px;
      border-radius: 15px;

      &:hover {
        background-color: $color-secondary-3;
      }
    }
  }
}

.table__item {
  padding: 10px 20px !important;

  &.status {
    .dot {
      height: 25px;
      width: 25px;
      background-color: #bbb;
      border-radius: 50%;
      display: inline-block;

      &.green {
        background-color: $color-green-primary;
      }

      &.orange {
        background-color: $color-calendar-orange;
      }
    }
  }

  &.final-client {
    &::after {
      font-family: $font-icomoon;
      content: "\e934";
      display: inline-block;
      font-size: 32px;
      color: $color-accent-3;
      cursor: pointer;
    }

    &:hover {
      &::after {
        color: $color-accent-1;
      }
    }

    &.disabled {
      &::after {
        cursor: not-allowed;
      }

      &:hover {
        &::after {
          color: $color-grey-light;
        }
      }
    }
  }

  .pdf::after {
    font-family: $font-icomoon;
    content: "\e932";
    display: inline-block;
    font-size: 26px;
    right: 15px;
    cursor: pointer;
    color: $color-black;
  }

  .edit::after {
    font-family: $font-icomoon;
    content: "\e92d";
    display: inline-block;
    font-size: 18px;
    cursor: pointer;
    margin-left: 20px;
  }

  .info {
    border: none;
    padding: 0;
  }

  .warning-icon::after {
    font-family: $font-icomoon;
    content: "\e92a";
    display: inline-block;
    font-size: 18px;
    color: $color-primary;
    margin-right: 10px;
  }

  &.contact::after {
    font-family: $font-icomoon;
    content: "\e935"; // @TODO -- intégrer la bonne icone sur icommon
    display: inline-block;
    font-size: 28px;
    cursor: pointer;
    color: $color-accent-3;
  }
}

.table__line.total-line {
  position: static;
  justify-content: end;
  background-color: #bbb;
  width: 1260px;

  .table__item {
    border-bottom: none;

    &.total {
      text-transform: uppercase;
    }
  }
}

.table__line {
  &.clickable {
    cursor: pointer;
  }

  .table__sticky {
    z-index: 10;
    background-color: $color-white;
  }
}

.table__item.info-client,
.table__item.info-dealer {
  padding: 0;
}

.table__title {
  background: #f5f5f5;
  border: 1px solid #e4e4e4;
  text-transform: uppercase;
  color: #829198;
  font-weight: 700;
  position: relative;

  &::after {
    display: block;
    font-family: $font-icomoon;
    font-size: 20px;
    color: $color-secondary-3;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 5px;
  }

  &.arrow-icon::after {
    font-family: $font-icomoon;
    content: "\e908";
    display: inline-block;
    font-size: 7px;
    position: absolute;
    right: 15px;
  }

  &.calendar-icon::after {
    font-family: $font-icomoon;
    content: "\e933";
    display: inline-block;
    font-size: 16px;
    position: absolute;
    right: 15px;
  }
}

.table__title--sortable::after {
  content: "\e910";
  display: block;
  font-family: $font-icomoon;
  font-size: 20px;
  color: $color-secondary-3;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 5px;
}

.table__title--asc::after {
  content: "\e90f";
}

.table__title--desc::after {
  content: "\e910";
}

.table__item {
  &.status {
    .dot {
      height: 25px;
      width: 25px;
      background-color: #bbb;
      border-radius: 50%;
      display: inline-block;

      &.green {
        background-color: $color-green-primary;
      }

      &.orange {
        background-color: $color-calendar-orange;
      }
    }
  }

  &.delete::after {
    font-family: $font-icomoon;
    content: "\e92c";
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
  }

  &.final-client {
    &::after {
      font-family: $font-icomoon;
      content: "\e934";
      display: inline-block;
      font-size: 32px;
      color: $color-accent-3;
      cursor: pointer;
    }

    &:hover {
      &::after {
        color: $color-accent-1;
      }
    }
  }

  .pdf::after {
    font-family: $font-icomoon;
    content: "\e932";
    display: inline-block;
    font-size: 26px;
    right: 15px;
    cursor: pointer;
  }

  .edit::after {
    font-family: $font-icomoon;
    content: "\e92d";
    display: inline-block;
    font-size: 18px;
    cursor: pointer;
    margin-left: 20px;
  }

  .info {
    border: none;
    padding: 0;
  }

  form.location {
    position: relative;
    width: 100%;

    &::after {
      font-family: $font-icomoon;
      content: "\e908";
      display: inline-block;
      font-size: 7px;
      position: absolute;
      right: 15px;
      top: 15px;
      color: $color-accent-3;
    }
  }

  #location-select {
    border: none;
    background: none;
    width: 100%;
    height: 48px;
    cursor: pointer;
    appearance: none;
    padding: 0 20px;
  }
}

/* stylelint-enable */
